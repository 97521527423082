"use client"

import {
  createContext,
  type FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { useRouter, useSearchParams } from "next/navigation"
import { useSession } from "next-auth/react"
import { toast } from "sonner"

import { authenticateWithCLI } from "@/api/cliAuth"
import useOnce from "@/hooks/useOnce"
import { ROUTES } from "@/routes"
import { logError } from "@/utils/error"

import type { LoginContextProviderProps, LoginContextValue } from "./types"

import { type AuthMethod, type GitHubState } from "../types"

const LoginContext = createContext<LoginContextValue | undefined>(undefined)

export const LoginContextProvider: FC<LoginContextProviderProps> = ({
  children,
}) => {
  const [signingIn, setSigningIn] = useState<AuthMethod | false>(false)
  const searchParams = useSearchParams()
  const router = useRouter()
  const session = useSession()

  const githubRedirectState = useMemo(() => {
    let state = null

    if (searchParams.has("state")) {
      state = JSON.parse(searchParams.get("state")!) as GitHubState
    }

    return state
  }, [searchParams])

  const redirectURL = useMemo(
    () => searchParams.get("callbackUrl") ?? githubRedirectState?.callbackUrl,
    [searchParams, githubRedirectState],
  )

  const sourceParam = useMemo(
    () => searchParams.get("source") ?? githubRedirectState?.source,
    [searchParams, githubRedirectState],
  )

  const endpointURLParam = useMemo(
    () => searchParams.get("redirecturi") ?? githubRedirectState?.redirecturi,
    [searchParams, githubRedirectState],
  )

  const redirectToApp = useCallback(() => {
    const isPastWaitlist = session.data?.user.waitlist_status === "success"
    const redirectRoute = isPastWaitlist
      ? (redirectURL ?? ROUTES.UserDashboard)
      : ROUTES.Waitlist

    router.push(redirectRoute)
  }, [redirectURL, router, session])

  const value = useMemo(() => {
    const val: LoginContextValue = {
      signingIn,
      setSigningIn,
    }

    return val
  }, [signingIn, setSigningIn])

  const authenticateCLI = useCallback(async (endpointURL: string) => {
    const loadingToast = toast.loading("Authenticating with CLI")

    try {
      const status = await authenticateWithCLI(endpointURL)

      if (status === 200) {
        toast.success("CLI authentication succeeded")
      } else {
        toast.error("CLI authentication failed")
      }
    } catch (error) {
      toast.error("CLI authentication failed")

      if (error) {
        logError(error as Error)
      }
    } finally {
      toast.dismiss(loadingToast)
    }
  }, [])

  /**
   * Authenticate with CLI if the user is already authenticated.
   */
  useOnce(
    () => {
      if (
        session.status === "authenticated" &&
        sourceParam === "cli" &&
        endpointURLParam != null
      ) {
        authenticateCLI(endpointURLParam)
      }
    },
    undefined,
    session.status === "authenticated" && sourceParam != null,
  )

  /**
   * http://localhost:3000/login?code=1f213017fc3e86567a66&state=%7B%22currentTab%22%3A%22SignUp%22%2C%22callbackUrl%22%3Anull%7D
   * Redirect users to app once authenticated.
   */
  useOnce(redirectToApp, undefined, session.status === "authenticated")

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}

export const useLoginContext = () => {
  const context = useContext(LoginContext)

  if (!context) {
    throw new Error(
      "`useLoginContext` must be used within a `LoginContextProvider`.",
    )
  }

  return context
}
