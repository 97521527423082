import { type Session, getServerSession } from "next-auth"
import { getSession } from "next-auth/react"

import { AUTH_OPTIONS } from "@/app/api/auth/[...nextauth]/config"
import { IS_BROWSER } from "@/constants"
import { logError } from "@/utils/error"
import { request } from "@/utils/request"

export const authenticateWithCLI = async (endpointURL: string) => {
  let response

  try {
    let clientSession: Session | null = null
    let serverSession: Session | null = null

    if (IS_BROWSER) {
      clientSession = await getSession()
    } else {
      serverSession = await getServerSession(AUTH_OPTIONS)
    }

    const user = clientSession?.user ?? serverSession?.user

    response = await request(endpointURL, "POST", {
      body: { AuthToken: user?.authToken },
    })
  } catch (error) {
    logError(error as Error)
  }

  return response?.status
}
